import React from 'react'
import styled from '@emotion/styled'
import LinkedInIcon from '../icons/linkedin-square.svg'
import WebsiteIcon from '../icons/website.svg'

const Wrapper = styled.div`
  margin: 40px 0;
  padding: 22px;
  box-shadow: 0px 2px 6px 0px rgba(0,0,0, 0.2);
  border-radius: 20px;

  @media(min-width: 500px) {
    margin: 60px 20px;
    padding: 30px;
  }
`

const Text = styled.div`
  font-style: italic;
`

const InfoRow = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Avatar = styled.img`
  width: 100px;
  border-radius: 50%;
`

const PersonalInfoColumn = styled.div`
  margin-left: 20px;
`

const Name = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`

const Role = styled.div`
  font-size: 14px;
  line-height: 17px;
  margin-top: 4px;
`

const IconWrapper = styled.div`
  display: flex;
  margin-top: 10px;
`

const Icon = styled.img`
  margin-right: 10px;
`

function Testimonial({ image, name, role, text, linkedin, website }) {
  return (
    <Wrapper>
      <Text>
        {text}
      </Text>

      <InfoRow>
        <Avatar src={image} />
        <PersonalInfoColumn>
          <Name>{name}</Name>
          {role && <Role>{role}</Role>}

          <IconWrapper>
            {
              linkedin && <a href={linkedin}><Icon src={LinkedInIcon} /></a>
            }
            {
              website && <a href={website}><Icon src={WebsiteIcon} /></a>
            }
          </IconWrapper>
        </PersonalInfoColumn>
      </InfoRow>
    </Wrapper>
  )
}

export default Testimonial;
